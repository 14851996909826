<template>
  <main class="main page orders-page newproject">
    <section class="section">
      <h1 class="section__heading">My Orders</h1>
      <div class="orders orders--active">
        <template v-if="recentOrders?.length">
          <div v-for="order in recentOrders" :key="order.id" :id="order.stripeOrderId">
            <Order :isOpen="false" :order="order" :ref="order.stripeOrderId" />
          </div>
        </template>
        <div v-else class="orders__no-orders">
          <h2>No recent orders.</h2>
          <router-link to="/registration">
            <button class="primary customGreenBTN">
              Start a project  &nbsp; <span class="icon-arrow-right"></span>
            </button>
          </router-link>
        </div>
      </div>
    </section>
    <section v-if="pastOrders.length" class="section">
      <h1 class="section__heading">Past Orders</h1>
      <div class="orders orders--past">
        <div v-for="(order, index) in pastOrders" :key="index">
          <Order :isOpen="false" :order="order" />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
// import { collection, getDocs, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import Order from '@/components/Order'
export default {
  name: 'Orders',
  components: {
    Order
  },
  data () {
    return {
      myOrders: null,
      myOrdersFormatted: null,
      myOrdersPastFormatted: null
    }
  },
  computed: {
    ...mapState(['fbFunctions', 'firestore']),
    ...mapGetters(['userProfile', 'currentUser']),
    /**
     * Returns a date 30 days before the present
     */
    thirtyDaysAgo () {
      return new Date().getTime() - (30 * 24 * 60 * 60 * 1000) // days, hours, minutes, seconds, ms
      // return new Date().getTime() - (30 * 60 * 1000) // older than 30 minutes ago (for testing)
    },
    /**
     * Returns all orders which are newer than 30 days ago.
     */
    recentOrders () {
      if (!this.myOrdersFormatted) return []
      const recent = this.myOrdersFormatted
      return recent
    },
    /**
     * Returns all orders which are older than 30 days ago.
     */
    pastOrders () {
      if (!this.myOrdersPastFormatted) return []
      const past = this.myOrdersPastFormatted.filter((order) => {
        return order
      })
      return past
    }
  },
  methods: {
    ...mapActions(['getTargetSurvey', 'getOrder', 'getLineItem', 'getNewLineItem']),
    ...mapMutations(['setPageLoading']),
    async getMyOrders () {
      const res = await httpsCallable(this.fbFunctions, 'getStripeOrders')('MyOrders')
      this.myOrders = res.data

      // We only want to display "complete" orders here
      this.myOrdersFormatted = this.myOrders
      // This is code use for data get from stripe
      for (const i in this.myOrdersFormatted) {
        // Get the survey data associated with each order
        const targetOrder = this.myOrdersFormatted[i]
        let paymentJson = {}
        if (targetOrder.paymentJson) {
          paymentJson = JSON.parse(targetOrder.paymentJson)
        }
        if (paymentJson.object === 'checkout.session') {
          targetOrder.metadata = paymentJson.metadata
          // var itemIds = []
          this.myOrdersFormatted[i].paymentJson = paymentJson
          this.myOrdersFormatted[i].created = paymentJson.created
          this.myOrdersFormatted[i].line_items = paymentJson.line_items
          const surveyId = targetOrder.metadata.selectionsId || targetOrder.metadata.surveyId || ''
          try {
            if (surveyId !== '') {
              this.myOrdersFormatted[i].surveyData = localStorage.getItem(surveyId)
            }
          } catch (err) {

          }
        } else {
          const targetOrder = this.myOrdersFormatted[i]
          let paymentJson = {}
          if (targetOrder.paymentJson) {
            paymentJson = JSON.parse(targetOrder.paymentJson)
          }

          var surveyId = paymentJson.metadata.selectionsId || paymentJson.metadata.surveyId || ''
          this.myOrdersFormatted[i].paymentJson = paymentJson
          this.myOrdersFormatted[i].created = paymentJson.created
          this.myOrdersFormatted[i].metadata = paymentJson.metadata
          this.myOrdersFormatted[i].shipping_details = paymentJson?.shipping_details
          if (paymentJson.metadata.orderType === 'furniture') {
            this.myOrdersFormatted[i].line_items = paymentJson.line_items
          }
          try {
            if (surveyId !== '') {
              this.myOrdersFormatted[i].surveyData = localStorage.getItem(surveyId)
            }
          } catch (err) {

          }
        }

        if (parseInt(i) === parseInt(1)) {
          this.setPageLoading(false)
        }
      }
    },
    async getPastOrders () {
      const res = await httpsCallable(this.fbFunctions, 'getStripeOrders')('PastOrders')
      this.myOrders = res.data

      // We only want to display "complete" orders here
      this.myOrdersPastFormatted = this.myOrders
      // This is code use for data get from stripe
      for (const i in this.myOrdersPastFormatted) {
        // Get the survey data associated with each order
        const targetOrder = this.myOrdersPastFormatted[i]
        var paymentJson = {}
        paymentJson = JSON.parse(targetOrder.paymentJson)
        if (paymentJson.object === 'checkout.session') {
          targetOrder.metadata = paymentJson.metadata
          this.myOrdersPastFormatted[i].paymentJson = paymentJson
          this.myOrdersPastFormatted[i].created = paymentJson.created
          this.myOrdersPastFormatted[i].line_items = paymentJson.line_items
          const surveyId = targetOrder.metadata.selectionsId || targetOrder.metadata.surveyId || ''
          try {
            // const surveyData = await this.getTargetSurvey({ id: surveyId })
            // this.myOrdersPastFormatted[i].surveyData = surveyData
            if (surveyId !== '') {
              this.myOrdersPastFormatted[i].surveyData = localStorage.getItem(surveyId)
            }
          } catch (err) {

          }
        } else {
          const targetOrder = this.myOrdersPastFormatted[i]
          const paymentJson = JSON.parse(targetOrder.paymentJson)
          var surveyId = paymentJson.metadata.selectionsId || paymentJson.metadata.surveyId || ''
          this.myOrdersPastFormatted[i].paymentJson = paymentJson
          this.myOrdersPastFormatted[i].created = paymentJson.created
          this.myOrdersPastFormatted[i].metadata = paymentJson.metadata
          this.myOrdersPastFormatted[i].shipping_details = paymentJson?.shipping_details
          if (paymentJson.metadata.orderType === 'furniture') {
            this.myOrdersPastFormatted[i].line_items = paymentJson.line_items
          }
          try {
            if (surveyId !== '') {
              // const surveyData = await this.getTargetSurvey({ id: surveyId })
              // this.myOrdersPastFormatted[i].surveyData = surveyData
              if (surveyId !== '') {
                this.myOrdersPastFormatted[i].surveyData = localStorage.getItem(surveyId)
              }
            }
          } catch (err) {

          }
        }
      }
    }
  },
  async mounted () {
    // Don't attempt to get orders unless the user has a Stripe ID
    if (this.userProfile.stripeCustomerId) {
      await this.getMyOrders()
      this.setPageLoading(false)
      await this.getPastOrders()
    } else {

    }
    this.setPageLoading(false)
    // If we're looking for a specific order, scroll to it.
    const targetOrder = this.$route.query.id
    if (this.$refs[targetOrder] && typeof this.$refs[targetOrder].toggleOrder === 'function') {
      //
      this.$refs[targetOrder[0]].toggleOrder()
      setTimeout(() => {
        document.getElementById(targetOrder[0]).scrollIntoView()
      }, 100)
    }
  }
}
</script>

<style lang="scss">
.orders-page {
  .section {
    grid-column-start: 2;
    grid-column-end: 12;

    @include breakpoint($s) {
      grid-column: span 12;
    }
  }
  .orders {
    grid-column: span 12;

    &__no-orders {
      background-color: $tt-classic-white;
      padding: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include breakpoint($s) {
        padding: 1rem;
      }

      h2 {
        flex: 1;
        font-family: 'GT America Expanded';
        font-size: 1.5rem;
        font-weight: 300;

        @include breakpoint($s) {
          font-size: 1rem;
        }
      }
      button {
        flex: 2;
      }
    }
  }
}
</style>
